<template>
  <div class="search-input-wrap" v-click-away="onAwayClick">
    <icon
      :class="{ focused }"
      name="search"
      class="search-icon"
      width="14"
      height="16"
    />
    <input
      v-model="searchStr"
      :placeholder="placeholder"
      :class="{ focused }"
      @focus="onFocus"
      @blur="onBlur"
      type="text"
      class="search-input-wrap__field"
    />
    <div v-if="focused || searchStr" class="cancel-wrap" @click="onClear">
      <icon name="cancel" width="11" height="11" class="cancel-icon" />
    </div>
    <div v-show="focused && filteredList.length" class="search-result-list">
      <div
        v-for="(item, ndx) in filteredList"
        :key="ndx"
        class="search-result-list__item"
        @click="setMarket(item)"
      >
        <div class="search-result-list__item-name">
          {{ getMarketTitle(item) }}
        </div>
        <div class="search-result-list__item-category">
          {{ item.group?.toUpperCase() }}
        </div>
        <FavouriteStar
          :active-market="item"
          class="star-wrap"
          @click.stop="favClick"
        />
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/icons/search';
import '@/assets/icons/cancel';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import FavouriteStar from '@/components/ui/FavouriteStar';
import { setRouteFromActiveMarket } from '@/helpers/activeMarketRoute';
import useMarketCoin from '@/compositions/useMarketCoin';
import { marketLabel, marketTicker } from '@/config/wrapMarkets';

export default {
  components: {
    FavouriteStar,
  },
  props: {
    inChart: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const searchStr = ref('');
    const stopBlur = ref(false);
    const focused = ref(false);
    const { getTickerName } = useMarketCoin();

    const activeMarket = computed(() => {
      return store.getters['markets/activeMarket'];
    });

    const searchSaveList = computed(
      () => store.getters['localUiSettings/marketsSearchHistory']
    );

    const markets = computed(() => store.getters['markets/markets']);

    const marketsByName = computed(
      () => store.getters['markets/marketsByName']
    );

    const placeholder = computed(() => {
      if (props.inChart) {
        return marketTicker(activeMarket.value?.name);
      }
      return 'SEARCH MARKETS';
    });

    const filteredList = computed(() => {
      if (!searchStr.value) {
        return markets.value.filter((item) =>
          searchSaveList.value.includes(item.name)
        );
      }
      const list = markets.value.filter((market) => {
        const isSameName = market.name
          .toLowerCase()
          .includes(searchStr.value.toLowerCase());

        const isSameTicker = [
          marketTicker(market.name),
          marketLabel(market.name),
        ].some((value) =>
          value.toLowerCase().includes(searchStr.value.toLocaleLowerCase())
        );

        return isSameName || isSameTicker;
      });

      return list;
    });

    const setMarket = async (item) => {
      searchStr.value = '';
      focused.value = false;
      store.dispatch('localUiSettings/setMarketsSearchHistory', item.name);
      store.commit(
        'markets/UPDATE_ACTIVE_MARKET',
        marketsByName.value[item.name]
      );
      setRouteFromActiveMarket({ stopDefault: true });

      if (activeMarket.value?.id) {
        await store.dispatch(
          'volumeLimits/fetchLoyaltyFeeInfo',
          activeMarket.value.id
        );
      }
      searchStr.value = '';
    };

    const onFocus = () => {
      focused.value = true;
    };

    const onBlur = () => {
      setTimeout(() => {
        if (stopBlur.value) {
          return;
        }
        focused.value = false;
      }, 200);
    };

    const onClear = () => {
      searchStr.value = '';
    };

    const favClick = () => {
      stopBlur.value = true;
      setTimeout(() => {
        stopBlur.value = false;
      }, 200);
    };

    const onAwayClick = () => {
      focused.value = false;
    };

    const getMarketTitle = (market) => {
      if (market.name.includes('/')) {
        const isDublicateName = market.name === marketLabel(market.name);
        return isDublicateName
          ? market.name
          : `${market.name}, ${marketLabel(market.name)}`;
      }
      return `${market.pair}, ${market.name}`;
    };

    const isFavourite = (market) =>
      !!store.getters['settings/isFavouriteMarket'](getTickerName(market));

    return {
      searchStr,
      filteredList,
      focused,
      activeMarket,
      placeholder,
      onAwayClick,
      setMarket,
      getMarketTitle,
      onFocus,
      onBlur,
      onClear,
      isFavourite,
      favClick,
    };
  },
};
</script>
<style lang="scss">
@import '@/assets/styles/panels.scss';

.search-input-wrap {
  position: relative;

  .search-result-list {
    width: 100%;
    border-radius: 6px;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    background: $backgroundPanelColorDark;
    position: absolute;
    z-index: 1001;
    margin-top: 9px;

    &__item {
      position: relative;
      display: flex;
      width: 100%;
      height: 50px;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      padding-right: 43px;
      border-bottom: 1px solid $borderSeparateColorDark;
      cursor: pointer;

      .star-wrap {
        padding: 12px;
        width: 40px;
        height: 50px;
        position: absolute;
        right: 3px;
        top: 0;
        z-index: 11;
      }

      &:hover {
        background: $backgroundHoverColorDark;

        .search-result-list__item-name {
          color: $colorHoverNameDark;
        }

        .search-result-list__item-category {
          color: $colorHoverCategoryDark;
        }
      }
    }

    &__item-name {
      font-family: Gotham_Pro_Medium;
      color: $colorNameDark;
      font-size: 15px;
    }

    &__item-category {
      margin-top: 3px;
      font-family: Gotham_Pro_Regular;
      font-size: 15px;
      color: $colorCategoryDark;
    }
  }

  svg.search-icon {
    top: 14px;
    position: absolute;
    left: 15px;
    color: $searchIconColorDark;

    &.focused {
      color: $searchIconColorFocusedDark;
    }
  }

  .cancel-wrap {
    position: absolute;
    right: 0;
    top: 0;
    height: 44px;
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  svg.cancel-icon {
    color: $colorCancelIconDark;
  }

  &__field {
    width: calc(100% - 2px);
    margin-left: 1px;
    // transition: all .2s ease-in-out;
    // width: 100%;
    outline: none;
    border: none;
    text-align: center;
    background-color: $backgroundColorFieldDark;
    color: $searchIconColorDark;
    font-family: Gotham_Pro_Regular;
    font-size: 15px;
    border-radius: 6px;
    height: 44px;
    line-height: 44px;
    //padding-top: 4px;
    text-transform: uppercase;
    padding: 0 40px;
    padding-top: 1px;

    @media screen and (min-width: 1024px) {
      padding-top: 3px;
    }

    &.focused {
      color: $searchIconColorFocusedDark;
      text-align: left;
    }
  }
}

::-webkit-input-placeholder {
  color: $inputPlaceHolderColorDark;
}
:-moz-placeholder {
  color: $inputPlaceHolderColorDark;
  opacity: 1;
}
::-moz-placeholder {
  color: $inputPlaceHolderColorDark;
  opacity: 1;
}
:-ms-input-placeholder {
  color: $inputPlaceHolderColorDark;
}
::-ms-input-placeholder {
  color: $inputPlaceHolderColorDark;
}
::placeholder {
  color: $inputPlaceHolderColorDark;
}

body.light {
  ::-webkit-input-placeholder {
    color: $inputPlaceHolderColorLight;
  }
  :-moz-placeholder {
    color: $inputPlaceHolderColorLight;
    opacity: 1;
  }
  ::-moz-placeholder {
    color: $inputPlaceHolderColorLight;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: $inputPlaceHolderColorLight;
  }
  ::-ms-input-placeholder {
    color: $inputPlaceHolderColorLight;
  }
  ::placeholder {
    color: $inputPlaceHolderColorLight;
  }

  .search-input-wrap {
    .search-result-list {
      background: $backgroundPanelColorLight;

      &__item {
        border-bottom: 1px solid $borderSeparateColorLight;

        &:hover {
          background: $backgroundHoverColorLight;

          .markets-table__field-fav.star-wrap {
            svg g {
              fill: $starIconHoverLight !important;
            }
          }

          .search-result-list__item-name {
            color: $colorHoverNameLight;
          }

          .search-result-list__item-category {
            color: $colorHoverCategoryLight;
          }
        }
      }

      &__item-name {
        color: $colorNameLight;
      }

      &__item-category {
        color: $colorCategoryLight;
      }
    }

    svg.search-icon {
      color: $searchIconColorLight;

      &.focused {
        color: $searchIconColorFocusedLight;
      }
    }

    svg.cancel-icon {
      color: $colorCancelIconLight;
    }

    &__field {
      background-color: $backgroundColorFieldLight;
      color: $searchIconColorLight;

      &.focused {
        color: $searchIconColorFocusedLight;
      }
    }
  }
}
</style>
