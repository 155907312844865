/* eslint-disable */
import * as d3Format from "d3-format";
//import DateTime from 'luxon/src/datetime.js';

import bsvPrice from "../compositions/bsvPrice";
import store from "@/store";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { floatList } from "@/helpers/floatList";
import { WalletNames } from "@/wallets/common";
// util functions should go here
// convert sum to currency format, exapmle: 1 000 000 (use in markets)
export const getVolumeFormat = (sum) =>
  sum < 1 ? sum : sum.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");

export const getNumFormat = (n, digit) => {
  const val = `${n}`;
  if (!val.includes(".")) {
    return val;
  }

  const floatValue = val.split(".");
  return `${floatValue[0]}.${floatValue[1].slice(0, digit)}`;
};

export const toSplitValue = (strValue) => {
  // Регулярное выражение для разбора знака, символа "$" и числа
  const match = strValue.match(/^([+-]?)\$?(\d+)$/);

  // Если формат не соответствует, вернуть исходную строку
  if (!match) {
      throw new Error("Неправильный формат строки");
  }

  const sign = match[1]; // Знак (+ или -)
  const number = match[2]; // Число

  // Форматируем число, добавляя пробелы между разрядами
  const formattedNumber = number.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  // Возвращаем результат с исходным знаком и символом "$"
  return `${sign}$${formattedNumber}`;
};

export const isEmail = (email) => {
  return !!String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const shortBigNumber = (n) => {
  const num = +(n + "").replaceAll(" ", "").replaceAll("−", "-");
  // console.log('NNN', num, n);

  const moreK = Math.abs(num) > 9999;
  const moreM = Math.abs(num) > 999999;
  const lessK = Math.abs(num) >= 100 && Math.abs(num) < 1000;

  if (Math.abs(num) >= 1 && Math.abs(num) < 100) {
    return Math.sign(num) * Math.abs(num).toFixed(2);
  }

  if (Math.abs(num) > 1000 && Math.abs(num) < 10000) {
    return Math.sign(num) * Math.abs(num).toFixed(0);
  }

  if (moreK && !moreM) {
    return Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + "k";
  }
  if (moreM) {
    return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "m";
  }
  if (lessK) {
    return Math.sign(num) * Math.abs(num).toFixed(2);
  }

  return Math.sign(num) * Math.abs(num).toFixed(4);
};

// converts amount to shortened format
export const amountValueShortener = (amount) => {
  let valuesToSymbols = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "m" }
  ];
  let regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = valuesToSymbols.length - 1; i > 0; i--) {
    if (amount >= valuesToSymbols[i].value) {
      break;
    }
  }
  return (
    (amount / valuesToSymbols[i].value).toFixed(1).replace(regex, "$1") +
    valuesToSymbols[i].symbol
  );
};

// get max value from array[{}, {}, {}]
export const getMaxSum = (items, field) => {
  return Math.max.apply(
    null,
    items.map((item) => item[field])
  );
};

const formatter = d3Format.formatLocale({
  decimal: ".",
  thousands: " ",
  grouping: [3],
  currency: ["$", ""],
  nan: ""
}).format;

const formatters = {
  point: {
    nospace: {
      0: formatter(".0f"),
      1: formatter(".1f"),
      2: formatter(".2f"),
      3: formatter(".3f"),
      4: formatter(".4f"),
      5: formatter(".5f"),
      6: formatter(".6f")
    },
    space: {
      0: formatter(",.0f"),
      1: formatter(",.1f"),
      2: formatter(",.2f"),
      3: formatter(",.3f"),
      4: formatter(",.4f"),
      5: formatter(",.5f"),
      6: formatter(",.6"),
      7: formatter(",.7f"),
      8: formatter(",.8f")
    }
  },
  nopoint: {
    nospace: {
      2: formatter(".2~f"),
      4: formatter(".4~f"),
      6: formatter(".6~f")
    },
    space: {
      2: formatter(",.2~f"),
      4: formatter(",.4~f"),
      6: formatter(",.6~f")
    }
  }
};

export const toTierFormat = (val1, val2) => {
  const percent = ((val1 / val2) * 100).toFixed(2);
  const points = Math.floor(val1 / val2);

  return `${points} (${percent >= 100 ? percent % 100 : percent}%)`;
};

export const toFixedAmount = (val) => {
  const n = +(val + "").replaceAll(" ", "");

  if (!n) {
    return "";
  }
  if (n >= 0.0001 && n < 0.1) {
    return parseInt(n * 10000) / 10000 + "";
  }
  if (n >= 0.1 && n < 1) {
    return parseInt(n * 1000) / 1000 + "";
  }
  if (n >= 1 && n < 100) {
    return parseInt(n * 100) / 100 + "";
  }
  if (n >= 100 && n < 1000) {
    return parseInt(n * 100) / 100 + "";
  }
  if (n >= 1000) {
    return parseInt(n * 100) / 100 + "";
  }

  return parseInt(n * 1000) / 1000 + "";
};

export const toUSDMarginFormat = (n, digits) => {
  let negative = false;
  if (digits === undefined) {
    digits = 0;
  }
  if (n < 0) {
    negative = true;
    n = n * -1;
  }
  const multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  n = (Math.ceil(n) / multiplicator).toFixed(digits);
  if (negative) {
    n = (n * -1).toFixed(digits);
  }
  return n;
};

export const getPrecision = (n) => {
  let precision = 6;

  const abs = Math.abs(+n);

  if (abs >= 1000) {
    precision = 2;
  } else if (abs >= 10) {
    precision = 4;
  }

  return precision;
};

export const toCurrencyFormat = (n, format, point) => {
  const isPoint = point !== null && point !== undefined;
  let formatterGroup = isPoint ? formatters.point : formatters.nopoint;
  formatterGroup =
    format === "nospace" ? formatterGroup.nospace : formatterGroup.space;
  let precision;
  if (isPoint) {
    precision = point;
  } else {
    precision = getPrecision(n);
  }
  return formatterGroup[precision](n);
};

export const toNumber = (string) => {
  return +string.replace("−", "-");
};

export const getFormatedAmount = (n, isUsd, decimals) => {
  const abs = Math.abs(+n);
  if (`${abs}`.includes("e")) {
    if (isUsd) {
      return "0.00";
    }
    return toCurrencyFormat(n, null, null);
  }

  if (isUsd) {
    if (abs === 0) {
      return toCurrencyFormat(n, null, null);
    } else if (abs < 0.00001) {
      const value = toCurrencyFormat(n, null, 6);
      return value.length > 7 ? value.slice(0, 5) : value;
    } else if (abs < 0.0001) {
      return toCurrencyFormat(n, null, 3);
    } else if (abs < 0.001) {
      return toCurrencyFormat(n, null, 3);
    } else if (abs < 0.01) {
      return toCurrencyFormat(n, null, 3);
    } else if (abs < 100) {
      return toCurrencyFormat(n, null, 2);
    } else if (abs < 1000) {
      return toCurrencyFormat(n, null, 1);
    } else {
      return toCurrencyFormat(n, null, null);
    }
  }
  return toCurrencyFormat(n, null, decimals || null);
};

export const removeSpaces = (val) => (!val ? val : val.replaceAll(" ", ""));

// short float currency
export const getFloatCurrency = (n, opts = {}) => {
  const options = { formatSpaces: true, ...opts };

  // check format to calculate. if format has xxxx or xxxx.xxxx then need in calculate
  // when foramt x/xx/xx or x.x / xx.x / xxx.xxx then not needed
  const isValidPretty = (val) =>
    (val.indexOf(".") === -1 && val.length > 3) || val.indexOf(".") > 3;
  const convertToPretty = (val) => {
    // str after point does not needed in calculate
    const str = val.toString();
    const startPoint = str.indexOf(".");

    if (startPoint > -1) {
      const prettyStr = str
        .slice(0, startPoint)
        .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
      const endStr = str.slice(startPoint);

      // concat start str with spaces + after point str
      return endStr.length > 1 ? `${prettyStr}${endStr}` : `${prettyStr}`;
    }

    return str.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
  };

  const inputValue = n.toString();
  const hasPoint = () => !!~inputValue.indexOf(".");
  const value = hasPoint() ? inputValue.replace(/0+$/, "") : inputValue;

  let val;

  if (value[0] === "0") {
    // after point - 0
    if (value[2] === "0") {
      val = value.slice(0, 6);
    } else {
      val = value.slice(0, 5);
    }
    return isValidPretty(val) && options.formatSpaces
      ? convertToPretty(val)
      : val;
  } else {
    // if first digit > 0, after point - 2 digit
    const pointNdx = value.indexOf(".");
    if (pointNdx > -1) {
      val = value.slice(0, pointNdx + 3);
    } else {
      val = value;
    }
  }

  if (val[val.length - 1] === ".") {
    val = val.slice(0, val.length - 1);
  }

  return isValidPretty(val) && options.formatSpaces
    ? convertToPretty(val)
    : val || "0";
};

export function getDateFromHours(dayNdx, hours, mins, date) {
  const delta = dayNdx - date.getDay();
  const newDate = new Date(date);
  // if (delta >= 0) {
  newDate.setDate(date.getDate() + delta);
  // } else {
  //   newDate.setDate(date.getDate() + 7 + delta)
  // }
  return newDate.setHours(hours, mins, 0, 0);
}

function whToArray({ wh_type, wh_s, wh_f }) {
  const ret = [];
  // if (wh_type === 'None') {}
  if (wh_type === "SunFri") {
    ret.push([
      [0, wh_s],
      [5, wh_f]
    ]);
  }
  if (wh_type === "WorkDays") {
    for (let i = 1; i <= 5; i++) {
      if (wh_s < wh_f) {
        ret.push([
          [i, wh_s],
          [i, wh_f]
        ]);
      } else {
        ret.push([
          [i, wh_s],
          [i + 1, wh_f]
        ]);
      }
    }
  }
  return ret;
}

function whArrayToDatesArray(date, arrTop) {
  const tzOffset = date.getTimezoneOffset();

  function fromUtcToLocalDate([val0, val1]) {
    val1 -= tzOffset;
    if (val1 < 0) {
      val1 += 1440;
      val0 -= 1;
      if (val0 < 0) {
        val0 += 7;
      }
    }
    if (val1 > 1440) {
      val1 -= 1440;
      val0 += 1;
      if (val0 > 6) {
        val0 -= 7;
      }
    }
    return getDateFromHours(val0, Math.floor(val1 / 60), val1 % 60, date);
  }

  const ret = [];
  for (let value of arrTop) {
    const dOpen = fromUtcToLocalDate(value[0]);
    const dClose = fromUtcToLocalDate(value[1]);
    if (dClose < dOpen) {
      dClose.setDate(dClose.getDate() + 7);
    }
    ret.push([dOpen, dClose]);
  }
  return ret.sort((a, b) => (a[0] > b[0] ? 1 : -1));
}

export function newWorkHours(date, market) {
  const { is_work_time: isOpen, until } = market;

  if (until === "24/7") {
    return ["24/7", "24/7"];
  }
  return [new Date(until) - new Date(), isOpen];

  // if (market.wh_type == 'None') return ['24/7', 'never']
  // const arr = whArrayToDatesArray(date, whToArray(market))
  // const mDate = DateTime.fromJSDate(new Date(date))
  // const minutesInWeek = 24 * 60 * 7
  // for (let dates of arr) {
  //   let minutesToOpen = DateTime.fromMillis(dates[0]).diff(mDate, 'minutes').minutes
  //   let minutesToClose = DateTime.fromMillis(dates[1]).diff(mDate, 'minutes').minutes
  //   if (minutesToOpen > minutesInWeek) minutesToOpen -= minutesInWeek
  //   if (minutesToClose > minutesInWeek) minutesToClose -= minutesInWeek
  //   if (minutesToOpen <= 0 && minutesToClose <= 0) continue

  //   if (minutesToOpen <= 0) return [minutesToClose, true]
  //   else return [minutesToOpen, false]
  // }

  // console.log('arr', arr)
  // return [DateTime.fromMillis(arr[0][0] + minutesInWeek * 60 * 1000).diff(mDate, 'minutes').minutes, false]
  // return [DateTime.fromJSDate(arr[0][0].setDate(arr[0][0].getDate() + 7)).diff(mDate, 'minutes').minutes, false]
}

export function isToOpen(date, market) {
  let ret = newWorkHours(date, market);
  return ret[1];
}

export function formatMinutes(min) {
  const m = Math.round(min);
  return m >= 1440
    ? `${Math.floor(m / 1440)}d ${
      Math.floor(m / 60) - 24 * Math.floor(m / 1440)
    }h ${m % 60}m`
    : m >= 60
      ? `${Math.floor(m / 60)}h ${m % 60}m`
      : `${m}m`;
}

const hexlist = "0123456789abcdef";
const b64list =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";

function guid_to_base64(g, le) {
  let s = g.replace(/[^0-9a-f]/gi, "").toLowerCase();
  if (s.length != 32) {
    return "";
  }

  if (le) {
    s =
      s.slice(6, 8) +
      s.slice(4, 6) +
      s.slice(2, 4) +
      s.slice(0, 2) +
      s.slice(10, 12) +
      s.slice(8, 10) +
      s.slice(14, 16) +
      s.slice(12, 14) +
      s.slice(16);
  }
  s += "0";

  let a, p, q;
  let r = "";
  let i = 0;
  while (i < 33) {
    a =
      (hexlist.indexOf(s.charAt(i++)) << 8) |
      (hexlist.indexOf(s.charAt(i++)) << 4) |
      hexlist.indexOf(s.charAt(i++));

    p = a >> 6;
    q = a & 63;

    r += b64list.charAt(p) + b64list.charAt(q);
  }
  r += "==";

  return r;
}

export function workHours(date, market) {
  let ret = newWorkHours(date, market);
  return ret[0] === "24/7" ? "24/7" : formatMinutes(ret[0] / 1000 / 60);
}

export function paymentUID() {
  let provider = store.getters["auth/provider"];
  const userId = store.getters["auth/userId"];
  const dxsAuthData = store.getters["auth/dxsAuthData"];
  // console.log('USER ID', userId)
  // let paymail = store.getters['user/loginPaymail']

  if (provider === WalletNames.handCash) {
    return `u${guid_to_base64(userId, true)}`; // 'l' + paymail.split('@')[0].replace('handcash.io', '') + '@hc'
  }

  if (provider === WalletNames.fiorin) {
    return `u${guid_to_base64(userId, true)}`;
  }

  let paymail_ = dxsAuthData?.providerId; // paymail.replace(/(@.+)/, '')

  let prov = (() => {
    switch (provider) {
      case WalletNames.fiorin:
        return "fr";
      case WalletNames.moneyButton:
        return "mb";
      case WalletNames.relayX:
        return "rx";
      case WalletNames.dotWallet:
        return "dw";
      case WalletNames.handCash:
        return "hc";
      default:
        return "xx";
    }
  })();

  return paymail_ === "..." ? "u" + userId : "l" + paymail_ + "@" + prov;
}

export const CSTimeSpanRegex =
  /^((?<minus>-)?)(((?<days>\d+)\.)?)(?<hours>\d\d):(?<minutes>(\d\d)):(?<seconds>(\d\d))(\.(?<doles>(\d+)))?/;
export const bsvFormat = (number) => {
  return toCurrencyFormat(number || 0, null, 5);
};
export const bsvFormat2 = (number) => {
  return toCurrencyFormat(number || 0, null, 2);
};

export const usdFormat = (number) => {
  return `$${toCurrencyFormat(number || 0, null, 2)}`;
};

export const usdFormatWithPercent = (value, percent) => {
  return `${getFormatedAmount(value, true)} USD (${percent.toFixed(2)}%)`;
};

export const formatAmount = (bsvAmount, postfix = "", ignoreMode) => {
  const isFiorin = store.getters["auth/isFiorin"];
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  let bsvMode = isLoggedIn && !isFiorin;

  if (ignoreMode) {
    bsvMode = false;
  }

  const bsvPart = (
    toCurrencyFormat(
      bsvAmount,
      null,
      bsvAmount > 100 ? 2 : 5 /*this.floatList['AccountBalanceBSV']*/
    ) || "0"
  ).replace(/ /g, "&nbsp;");
  const totalUsd = bsvAmount * (!bsvMode ? 1 : bsvPrice());
  const usdPart = toCurrencyFormat(
    totalUsd,
    null,
    totalUsd > 1000 ? 0 : 2
  ).replace(/ /g, "&nbsp;");

  if (store.getters["auth/isFiorinMode"] || ignoreMode) {
    return `</span>$${usdPart}`;
  }

  return `</span>$${usdPart}${postfix} <span style="opacity: 0.2;">❘</span> <span style="font-size: 13px">Ƀ </span>${bsvPart}${postfix} <span class="info-item_slash">`;
};

export const parseJwt = (token) => {
  if (!token || token.length < 100) {
    return null;
  }
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const parseParams = (obj) => {
  var str = [];

  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
    }
  }
  return str.join("&");
};

export const toSentenceCase = (str) => {
  if (!str) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const trim = (str, len) => {
  if (!str) {
    return str;
  }

  if (len >= str.length) {
    return str;
  }
  if (len <= 3) {
    return ".".repeat(len);
  }
  return str.slice(0, len - 3) + ".".repeat(3);
};

export const getAuthParamsFromRoute = (route) => {
  const state = route.query.state;
  const code = route.query.code;
  const handCashAuthToken = route.query.authToken; // handcash
  const mbAuthCode = code && state === "index" ? code : false; // mb
  const dwAuthCode = code && state === "dotwalletcode" ? code : false; // dw
  const isNotAuthPage = !dwAuthCode && !mbAuthCode && !handCashAuthToken;

  return {
    handCashAuthToken,
    mbAuthCode,
    dwAuthCode,
    isNotAuthPage
  };
};

export const usdBsvFormatAmount = (
  bsvAmount,
  postfix = "",
  hideChars,
  usdMode,
  usdInBsvMode
) => {
  // console.debug('#usdBsvFormatAmount', bsvAmount, postfix, hideChars, usdMode, usdInBsvMode);
  let bsvPart = (
    toCurrencyFormat(
      bsvAmount,
      null,
      bsvAmount > 100 ? 2 : floatList["AccountBalanceBSV"]
    ) || "0"
  ).replace(/ /g, "&nbsp;");
  const totalUsd =
    bsvAmount *
    (!usdInBsvMode && (usdMode || store.getters["auth/isFiorinMode"])
      ? 1
      : bsvPrice());
  let usdPart = toCurrencyFormat(
    totalUsd,
    null,
    totalUsd > 1000 ? 0 : totalUsd < 0.01 ? 4 : 2
  ).replace(/ /g, "&nbsp;");

  if (!usdPart.includes("−")) {
    usdPart = `${hideChars ? "" : "$"}${usdPart}`;
  }

  let bChar = "";
  if (!bsvPart.includes("−")) {
    bChar = `<span style="font-size: 13px; color: inherit;">${
      hideChars ? "" : "Ƀ"
    }</span>`;
  }

  if (usdMode || store.getters["auth/isFiorinMode"]) {
    return usdPart.replaceAll("−", `−${hideChars ? "" : "$"}`);
  }

  return (
    usdPart.replaceAll("−", `−${hideChars ? "" : "$"}`) +
    postfix +
    ` <span class="info-item_slash"><span style="opacity: 0.2;">❘ </span></span>${bChar} ${bsvPart.replaceAll(
      "−",
      `<span style="font-size: 13px; color: inherit;">− ${
        hideChars ? "" : "Ƀ"
      } </span>`
    )}${postfix}`
  );
};

export const disableScrolling = () => {
  const doc = document.querySelector("html");
  doc.classList.add("disable-scroll");
  doc.querySelector("body").classList.add("disable-scroll");
  disableBodyScroll(doc);
  disableBodyScroll(doc.querySelector(".modal"));
};

export const enableScrolling = () => {
  const doc = document.querySelector("html");
  doc.classList.remove("disable-scroll");
  doc.querySelector('body').classList.remove('disable-scroll');
  clearAllBodyScrollLocks();
};

export const delay = async (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));
