import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import createPersistedState from 'vuex-persistedstate';
import VueTour from 'v3-tour';
import { cachedPaths } from '@/store';
import 'v3-tour/dist/vue-tour.css';
import { widget } from '../public/charting_library_v28/charting_library/charting_library.esm';
import Icon from '@/components/ui/Icon.vue';
import VueClickAway from 'vue3-click-away';

import registerModule from './registerModule';
import settings from '@/modules/settings';
import modals from '@/modules/modals';
import markets from '@/modules/markets';
import user from '@/modules/user';
import burger from '@/modules/burger';
import features from '@/modules/features';
import session from '@/modules/session';
import positions from '@/modules/positions';
import formPosition from '@/modules/formPosition';
import bounty from '@/modules/bounty';
import i18nFactory from '@/i18n';
import { connMarkets, connApp } from '@/helpers/signalR';
import { initConsole } from '@/console';

import '@/assets/styles/main.scss';
import useDemopositions from '@/compositions/useDemopositions';
import { startReleaseChecker } from '@/helpers/releaseChecker';
import { initElapsedLoadTime } from '@/helpers/loadTimeTracker';
import throttle from 'lodash/throttle';

initElapsedLoadTime();

startReleaseChecker();
initConsole();

sessionStorage.setItem('appReadyStatus', 'init');

window.showState = function () {
  if (process.env.VUE_APP_IS_PRODUCTION === 'false') {
    console.nativeLog(store.state);
  }
};

window.showVuex = function () {
  if (process.env.VUE_APP_IS_PRODUCTION === 'false') {
    let s = JSON.parse(localStorage['vuex']);
    console.nativeLog(s);
  }
};

// [Vadim] do not await connMarkets, it significantly slows down the launch
// it will await internally in case if invoke happens before it finished connecting

logDebug('Starting connMarkets and connApp (dont wait)...');
connMarkets.start('main').catch((e) => {
  console.error('Error when starting connMarkets #signalR #dxs #main', e);
});

let options = {
  cachedPaths,
};
options = registerModule(modals, { store, options });
options = registerModule(burger, { store, options });
options = registerModule(user, { store, router, options });
options = registerModule(settings, { store, options });
options = registerModule(markets, { store, options });
options = registerModule(session, { store, options });
options = registerModule(positions, { store, options });
options = registerModule(features, { store, options });
options = registerModule(bounty, { store, options });
options = registerModule(formPosition, { store, options });

// multiple calls of createCachedPaths lead to bad behaviour, hence passing options in/out above
const persistenceDelay = 1_500;

createPersistedState({
  paths: options.cachedPaths,
  storage: {
    getItem: (key) => localStorage.getItem(key),
    setItem: throttle((key, value) => {
      localStorage.setItem(key, value);
    }, persistenceDelay),
    removeItem: (key) => localStorage.removeItem(key),
  },
})(store);

async function run() {
  const i18nPromise = i18nFactory();

  await store.dispatch('uiVisibility/init', null, { root: true });
  await store.dispatch('network/init', null, {
    root: true,
  });

  logDebug('Starting auth/initAuth (wait)...');
  await store.dispatch('auth/initAuth', null, { root: true });

  const isLoggedIn = store.getters['auth/isLoggedIn'];
  logDebug('After auth/initAuth', '\n', 'isLoggedIn:', isLoggedIn);

  let connAppPromise;
  if (!isLoggedIn) {
    // [Vadim] do not await connApp.start, it significantly slows down the launch
    // it will await internally in case if invoke happens before it finished connecting
    connAppPromise = connApp.start('main').catch((e) => {
      console.error('Error when starting connApp #signalR #dxs #main', e);
    });

    logDebug('Not logged in, initializing demopositions');
    const { initialDemoPositions } = useDemopositions(store);
    initialDemoPositions();
  }

  logDebug('Waiting i18nPromise');
  const i18nPromiseResult = await i18nPromise;

  if (connAppPromise) {
    await connAppPromise;
  }

  logDebug('Starting initVue');
  initVue(i18nPromiseResult);
  logDebug('Finished');
}

function initVue(i18n) {
  const app = createApp(App);
  app.provide('tv_widget', widget);

  app
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueTour)
    .use(VueClickAway)
    .component('icon', Icon)
    .mount('#app');
}

function logDebug() {
  console.debug(...arguments, '\n', '#main');
}

run().catch((err) => {
  console.error('Error starting Vue', err);
});
